import {
  GET_BACKER,
  GET_BACKER_SUCCESS,
  GET_BACKER_FAILED,
  GET_GOOGLE_CONVERSIONS,
  GET_GOOGLE_CONVERSIONS_SUCCESS,
  GET_GOOGLE_CONVERSIONS_FAILED,
} from "./consts"

export const getBacker = id => ({
  type: GET_BACKER,
  payload: { id },
})

export const getBackerSuccess = data => ({
  type: GET_BACKER_SUCCESS,
  payload: { data },
})

export const getBackerFailed = error => ({
  type: GET_BACKER_FAILED,
  payload: { error },
})

export const getConversions = id => ({
  type: GET_GOOGLE_CONVERSIONS,
  payload: { id },
})

export const getConversionsSuccess = data => ({
  type: GET_GOOGLE_CONVERSIONS_SUCCESS,
  payload: { data },
})

export const getConversionsFailed = error => ({
  type: GET_GOOGLE_CONVERSIONS_FAILED,
  payload: { error },
})
