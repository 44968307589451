import { all, takeEvery } from "redux-saga/effects"
import { GET_BACKER, GET_GOOGLE_CONVERSIONS } from "../actions/consts"
import handleGetBacker from "./get-backer"
import handleGetGoogleConversions from "./get-google-conversions"

export default function* sagas() {
  yield all([
    takeEvery(GET_BACKER, handleGetBacker),
    takeEvery(GET_GOOGLE_CONVERSIONS, handleGetGoogleConversions),
  ])
}
