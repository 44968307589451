if (typeof window !== "undefined") {
  window.dataLayer = window.dataLayer || []
}

function gtag() {
  window.dataLayer.push(arguments)
}

export function setupConsent() {
  if (localStorage.getItem("consentMode") === null) {
    gtag("consent", "default", {
      ad_storage: "denied",
      ad_user_data: "denied",
      analytics_storage: "denied",
      ad_personalization: "denied",
      functionality_storage: "denied",
      security_storage: "denied",
    })
  } else {
    gtag("consent", "default", JSON.parse(localStorage.getItem("consentMode")))
  }
}

export function setConsent(consent) {
  const consentMode = {
    ad_storage: consent.marketing ? "granted" : "denied",
    ad_user_data: consent.marketing ? "granted" : "denied",
    analytics_storage: consent.analytics ? "granted" : "denied",
    ad_personalization: consent.preferences ? "granted" : "denied",
    functionality_storage: consent.necessary ? "granted" : "denied",
    security_storage: consent.necessary ? "granted" : "denied",
  }
  gtag("consent", "update", consentMode)
  localStorage.setItem("consentMode", JSON.stringify(consentMode))
}

export function shouldDisplayBanner() {
  return localStorage.getItem("consentMode") === null
}

let tracker = {}

const track = code => {
  tracker[code] = tracker[code] + 1 || 1
}

const wasTracked = code => tracker[code] > 0

export const trackConversion = (
  tagId,
  { send_to, value, currency } = {},
  conversions = []
) => {
  if (wasTracked(tagId)) {
    return
  }

  gtag("js", new Date())
  gtag("config", tagId)

  if (!conversions || conversions?.length === 0) {
    gtag("event", "conversion", {
      send_to,
      value,
      currency,
    })
  } else {
    conversions.forEach(({ user_data, event_id }) => {
      gtag("set", "user_data", user_data)

      gtag("event", "conversion", {
        send_to,
        value,
        currency,
        transaction_id: event_id,
      })
    })
  }

  track(tagId)
}
